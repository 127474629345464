@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: var(--chatlook-secondary--);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Manrope", sans-serif; */
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --ev-primary--: #9ea8bd;
  --ev-secondary--: #dddde1;
  --ev-white--: #fff;
  --ev-black--: #191d23;
  --ev-darkblue--: #100322;
  --ev-green--: #037756;
  --ev-red--: #ef4444;

  --chatlook-primary--: #1b2130;
  --chatlook-secondary--: #131722;
  --chatlook-green--: #15cd2b;
  --chatlook-red--: #ef4444;
  --chatlook-white--: #fff;
  --chatlook-darksky--: #3a55eb;
}

/* ========================================= Login Page CSS ========================== */

.mainLoginPage {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.loginDiv {
  border-radius: 16px;
  width: 440px;
  margin: auto;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  overflow: hidden;
}

.loginPage {
  background-color: var(--chatlook-white--);
  padding: 40px;
}

.logoBar {
  height: 140px;
  background-color: var(--chatlook-secondary--);
  padding: 20px;
}

/* ========================================= Navbar Page CSS ========================== */

.mainNavbar {
  outline: 4px solid #f1f1f100;
  padding: 10px 18px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.title-nav {
  color: var(--ev-white--);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}

/* Nav Menu Toggle JS CSS */
.mobNav {
  margin-left: 75px;
}

/* Mobile Size */
/* @media screen and (max-width: 992px) {
  .webNav {
    margin-left: 75px;
  }

  .mobNav {
    margin-left: 220px;
  }
} */

/* ========================================= Sidebar Page CSS ========================== */

/* Side Menu Toggle JS CSS */

.sideBar.mobSidebar .sideBarLogo span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a > div > span,
.sideBar.mobSidebar .mainMenu > li > a > i,
.sideBar.mobSidebar .subMenu li a span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a {
  justify-content: start;
}

.sideBar.mobSidebar .mainMenu li a i {
  margin: 0;
}

.sideBar.mobSidebar .subMenu li a {
  padding-left: 22px;
}

/* End Side Menu Toggle JS CSS */

/* Mobile Size */
@media screen and (max-width: 992px) {
  /* .webSidebar {
    width: 75px;
  } */

  /* .sideBar .sideBarLogo span {
    display: none;
  } */

  /* .sideBar .mainMenu li a > div > span,
  .sideBar .mainMenu > li > a > i,
  .sideBar .subMenu li a span {
    display: none;
  } */

  /* .sideBar .mainMenu li a {
    justify-content: center;
  } */

  .sideBar .mainMenu li a i {
    margin: 0;
  }

  .sideBar .subMenu li a {
    padding-left: 20px;
  }

  /* ============ */

  .mobSidebar {
    width: 220px;
  }

  .sideBar.mobSidebar .sideBarLogo span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a > div > span,
  .sideBar.mobSidebar .mainMenu > li > a > i,
  .sideBar.mobSidebar .subMenu li a span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a > div {
    display: flex;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu > li > a {
    justify-content: space-between;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu li a i {
    margin-right: 10px;
  }

  .sideBar.mobSidebar .subMenu li a {
    padding-left: 30px;
  }
}

.rotate90 {
  transform: rotate(90deg);
  transition: 0.3s;
}

.mainSidebar {
  position: fixed;
  z-index: 11;
}

.sideBar {
  /* background-color: var(--chatlook-white--) !important; */
  background-color: #1b2130;
  height: 100vh;
  transition: 0.3s;
  overflow: hidden;
  /* border-right: 1px solid #e6edff; */
}

.sideBarLogo {
  padding: 16px;
}

.mainMenu > li {
  padding-bottom: 5px;
}

.mainMenu li a {
  color: var(--ev-primary--);
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-left: 3px solid transparent;
}

.mainMenu li > a:hover,
.mainMenu .activeMenu {
  color: var(--ev-white--);
  padding: 10px 0;
  border-radius: 0 50px 50px 0;
  border-left: 3px solid rgba(0, 144, 231, 1);
  background: var(--chatlook-secondary--);
  box-shadow: 0px 20px 50px 0px rgba(55, 69, 87, 0.1);
}

.mainMenu li a i {
  margin-right: 10px;
  transition: 0.3s;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

.subMenu {
  overflow: hidden;
}

right .subMenu li a {
  justify-content: start;
  padding-left: 30px;
}

.subMenu li a i {
  font-size: 6px;
}

/* ================================== Admin CSS =================================== */

.mainAdmin {
  border-radius: 25px;
  transition: 0.3s;
}

.mobAdmin {
  margin-left: 85px;
}

/* Dash */
.totaldepo-main {
  padding: 18px 20px 18px 8px;
  background: var(--chatlook-primary--);
  border-radius: 10px;
}
.totaldepo-text {
  padding-left: 10px;
  position: relative;
  /* border-left: 3px solid #C17FD0; */
}
.totaldepo-text::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100%;
  width: 4px;
  /* background-color: #C17FD0; */
  border-radius: 50px;
}
.purpleborder::after {
  background-color: #c17fd0;
}
.orangeborder::after {
  background-color: #ff993b;
}
.greenborder::after {
  background-color: #43b97f;
}
.yellowborder::after {
  background-color: #ffcc4a;
}
.incresetext {
  color: #2ac670;
}
.decresetext {
  color: #ff6252;
}

/* .box-img {
  padding: 10px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(124, 141, 181, 0.12);
} */
.box-img img {
  width: 45px;
}

.totaldepo-img-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.depomenu-btn span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12px 24px 0px rgba(174, 172, 242, 0.05);
}

.totaldepo-text h4 {
  padding-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
}

.totaldepo-text p {
  font-size: 14px;
  font-weight: 500;
}

.pass-mainsec {
  padding: 22px 20px;
}

.pass-mainsec h2 {
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
}

.profile-secmain {
  padding: 36px 20px;
}

.profile-detail h4 {
  font-size: 30px;
  font-weight: 500;
}

.profile-detail p {
  font-size: 16px;
  font-weight: 400;
}

.user-status {
  padding: 18px 22px;
  margin: auto;
  background: rgba(71, 62, 208, 0.07);
  margin-top: 40px;
}

.user-status p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.table-custome {
  border-radius: 8px 8px 0 0;
  box-shadow: unset;
}

.table-row {
  vertical-align: top !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 0px;
  border-radius: 50%;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(231, 231, 231);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8b89a9;
}

.main::-webkit-scrollbar {
  width: 4px;
}

/* .table-custome table {
  border-collapse: separate;
  border-spacing: 0 10px;
} */

.table-custome thead tr {
  border-bottom: 1px solid #2a344d;
  border-radius: 10px;
}

.table-custome tbody tr:not(.nodatafound) {
  border-radius: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.table-custome tbody .tr {
  outline: none !important;
}

.table-custome tbody tr td {
  padding: 10px 10px 10px 20px;
}

.table-custome thead tr th:nth-child(1) {
  padding-left: 20px;
}

.table-custome tbody tr td:nth-child(1) {
  padding-left: 12px;
}

/* .table-custome tbody tr:hover {
  box-shadow: 0px 10px 40px 0px rgba(86, 89, 146, 0.1);
} */

.table-custome thead tr th {
  padding: 2px 20px 10px 20px;
}

th {
  position: relative;
  text-align: left;
}

/* th::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 20px;
  width: 1px;
  background-color: #8b89a92d;
}
th:last-child::after {
  display: none;
} */

.table-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-heading {
  /* margin: 6px 0 10px; */
  font-size: 20px;
  font-weight: 600;
}

.tableborder {
  background-color: #1b2130;
  border-radius: 12px;
  padding: 10px 0 20px 0px;
}

.apply-clear {
  margin-top: 34px;
}

.appl-btn {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
  color: var(--ev-white--);
  font-size: 16px;
  border: 1px solid var(--chatlook-darksky--);
}

.appl-btn:hover {
  transition: 0.2s;
  border: 1px solid var(--chatlook-darksky--);
  background-color: var(--chatlook-white--);
  color: var(--chatlook-darksky--);
}

.clear-btn:hover {
  border: 1px solid var(--chatlook-darksky--) !important;
}
.ruppebtn:hover {
  border: 0px !important;
}

.login-main {
  z-index: 99999;
  padding: 44px 34px;
  border-radius: 8px;
  background: var(--chatlook-primary--);
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
}

.welc-text h4 {
  color: var(--ev-white--);
  font-size: 35px;
  font-weight: 700;
}
.welc-text p {
  color: #8f93ae;
  font-size: 16px;
  font-weight: 400;
}

.flatpickr-weekdays {
  background-color: var(--chatlook-secondary--) !important;
}

span.flatpickr-weekday {
  background-color: var(--chatlook-secondary--) !important;
  color: var(--ev-white--) !important;
}

.flatpickr-months .flatpickr-month {
  background-color: var(--chatlook-secondary--) !important;
  color: var(--ev-white--) !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: var(--chatlook-secondary--) !important;
  color: var(--ev-white--) !important;
}

.flatpickr-day.selected {
  background-color: var(--chatlook-secondary--) !important;
  color: var(--ev-white--) !important;
  border-color: var(--chatlook-secondary--) !important;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--chatlook-secondary--) !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 var(--chatlook-secondary--) !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange {
  background: var(--chatlook-secondary--) !important;
  border-color: var(--chatlook-secondary--) !important;
}

.pagination-ul ul {
  align-items: center;
}

/* Mobile Size */
@media screen and (max-width: 992px) {
  .mainAdmin {
    position: relative;
    z-index: 1;
    margin-left: 0;
  }

  .mainAdmin {
    margin-right: 10px;
  }
}

.flatpickr-day.endRange {
  background-color: var(--chatlook-secondary--) !important;
  color: var(--ev-white--) !important;
  border-color: var(--chatlook-secondary--) !important;
}

.drag-file-area {
  border: 1px dashed #eaecf0;
}

.drag-file-area .label .browse-files-text {
  cursor: pointer;
}

.browse-files div {
  position: relative;
  top: -25px;
}

.gif-success {
  mix-blend-mode: color-burn;
}

.select-img select {
  color: var(--ev-white--);
  border: 1px solid #222a40;
  /* background-color: var(--chatlook-secondary--); */
  background-image: url(./assets/images/downarrow-light.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

.mainbox {
  padding: 10px 0;
  /* border-radius: 12px; */
  /* border: 1px solid #e6edff; */
  /* background: var(--chatlook-white--); */
}

.celldetail {
  font-weight: 600;
  color: #0e012098;
}

.chartcss canvas {
  display: inline-block !important;
  width: 100% !important;
  vertical-align: unset;
}

.subtable thead tr {
  color: #8b8b8b;
}

.subtable thead th {
  font-weight: 400 !important;
}

.login-wrapper {
  height: 100vh;
  position: relative;
  margin: auto;
}

.login-inner {
  padding: 35px;
  width: 548px;
  border-radius: 24px;
  background: var(--chatlook-white--);
}

.LoginOne {
  width: 100%;
  position: absolute;
  top: 0;
}

.LoginTwo {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.TopLeft {
  position: absolute;
  top: 0;
  left: 0;
}
.TopRight {
  position: absolute;
  top: 0;
  right: 0;
}
.BottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
}
.BottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
}
.forgot-pass {
  color: #8f93ae;
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 525px) {
  .LoginOne {
    display: none;
  }

  .LoginTwo {
    display: none;
  }

  .welc-text h4 {
    font-size: 24px;
  }

  .welc-text p {
    font-size: 14px;
  }

  .login-main {
    padding: 30px 15px;
  }
}

.disabled {
  pointer-events: none !important;
}

.navigation {
  padding: 0 10px 0 0;
}

/* .react-switch-handle{
  height: 20px !important;
  width: 20px !important;
} */

.proof-sec button {
  border-radius: 6px;
  background: #6933d3;
  border: 0;
  padding: 8px 25px;
  border-radius: 6px;
  color: var(--ev-white--);
  font-size: 14px;
  font-weight: 600;
}

.proof-sec label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}

.proof-sec .label {
  /* height: 100%;
  max-height: 100%; */
  border-radius: 4px;
  border: 1px solid #222a40;
  background-color: var(--chatlook-secondary--);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: block;
  text-align: center;
}

.proof-sec .label p {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
/* 
.proof-sec .label:hover {
  background: #d0d5dd;
}

.proof-sec .label:active {
  background: #d0d5dd;
} */

.slefheight {
  height: 100% !important;
  max-height: 100% !important;
}

.ck-content.ck-editor__editable ol {
  margin-left: 14px;
}

.textareacss textarea {
  height: 91px;
  resize: none;
}

.react-datepicker {
  display: flex !important;
  flex-direction: column;
}

.action-button-wrapper {
  display: flex !important;
  gap: 3px;
  justify-content: flex-end;
}

.action-button-wrapper .action-button {
  border: 1px solid rgba(0, 0, 0, 0.23);
  outline: 1px solid transparent;
  padding: 4px 10px;
  border-radius: 2px;
}

.rc-time-picker-input {
  height: 100%;
  width: 100%;
  border: none !important;
  font-size: 14px;
  outline: 1px solid #d0d5dd !important;
  border-radius: 0px 4px 4px 0;
}
.rc-time-picker-input:focus-visible {
  outline: 1px solid #d0d5dd !important;
}

.rc-time-picker-input:focus {
  border: none !important;
}

.cardcss {
  padding: 16px;
  border-radius: 8px;
  background: #1b2130;
  box-shadow: 0px 0px 2px 0px rgba(16, 24, 40, 0.06),
    0px 0px 3px 0px rgba(16, 24, 40, 0.1);
}
.drop-main {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: transparent;
}
.dropdown-content {
  position: absolute;
  top: 45px;
  right: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px rgba(16, 24, 40, 0.06),
    0px 0px 3px 0px rgba(16, 24, 40, 0.1);
  background-color: var(--chatlook-white--);
  width: 140px;
  padding: 10px;
}

.bonus-time .MuiOutlinedInput-root {
  height: inherit;
  border-radius: 0 5px 5px 0;
  outline: 1px solid transparent;
}
.bonus-time .MuiOutlinedInput-root fieldset {
  border: 1px solid #222a40;
}
.times-error .MuiOutlinedInput-root fieldset {
  border: 1px solid #ef4444;
}
.times-error .MuiOutlinedInput-root:hover fieldset {
  border: 1px solid #ef4444 !important;
}
.bonus-time .MuiInputBase-root:hover fieldset {
  border: 1px solid #222a40;
  outline: 1px solid transparent;
}

.bonus-time .MuiMenuItem-root {
  background-color: #216ba5;
}
.react-datepicker-popper {
  z-index: 51;
}

@media only screen and (max-width: 768px) {
  .mainAdmin {
    margin: 0;
  }
}

@keyframes loadingAnimation {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
}

.loading-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #d9d9d9;
  animation: loadingAnimation 1.5s infinite ease-in-out;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.create-edit-bonus {
  width: 20px;
  margin: 0;
}
.custom-toast {
  display: flex;
  align-items: center;
  background-color: #f8edd7;
  color: #b67900;
  padding: 10px;
  border-radius: 5px;
}

.custom-toast svg {
  margin-right: 10px;
  fill: #b67900;
}

.loyltyborder thead tr {
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
}
.loyltyborder tbody tr {
  padding: 8px 0;
  border-bottom: 1px solid #f1f1f1;
}
.block-data {
  color: var(--ev-primary--);
  font-size: 14px;
  font-weight: 400;
}
.block-data-small {
  color: var(--ev-primary--);
  font-size: 11px;
  font-weight: 400;
}

.timeinput input::placeholder {
  color: var(--ev-secondary--) !important;
}
.timeinput input {
  color: var(--ev-white--) !important;
  font-size: 12px;
}
.timeinput svg {
  fill: var(--ev-white--) !important;
}

.ck.ck-reset_all a[href="https://ckeditor.com/ckeditor-5"]
{
  position: absolute;
  top: -9999em;
  left: -9999em;
}
.ql-toolbar.ql-snow {
  border: 1px solid #222a40 !important;
  background-color: var(--chatlook-secondary--);
}
.ql-editor {
  outline: 1px solid #222a40 !important;
  background-color: var(--chatlook-secondary--);
  color: #fff;
}
.ql-editor.ql-blank::before {
  color: #fff;
}
.ql-editor.ql-blank::before {
  color: #fff !important;
}

.serchsec {
  position: relative;
}
.serchsec input {
  width: 100%;
  padding: 8px 12px 8px 30px;
  border-radius: 8px;
  background: #131722;
  color: #6f778e;
  font-size: 12px;
  font-weight: 500;
}
.serchsec input:focus-visible {
  outline: 1px solid #1c1c1c;
}
.serchsec img {
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translate(-50%, -50%);
}
.serchsec span {
  color: var(--ev-primary--);
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%, -50%);
}

.react-datepicker {
  font-family: "Arial", sans-serif;
  background-color: var(--chatlook-primary--);
  color: #fff;
}

.react-datepicker__header {
  background-color: var(--chatlook-secondary--);
  color: #fff !important;
}
.react-datepicker__current-month {
  color: #fff;
}
.react-datepicker__day-names {
  /* color: #fff;  */
}
.react-datepicker__day-name {
  color: #fff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #6d6d6d;
}

.react-datepicker__day--selected {
  background-color: var(--chatlook-secondary--);
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--chatlook-secondary--);
  color: #fff;
}
.react-datepicker__day:hover {
  background-color: var(--chatlook-secondary--);
}
.MuiPickersLayout-root {
  background-color: var(--chatlook-secondary--);
  border: 1px solid white;
  color: white;
}
.MuiMenuItem-root {
  border-left: 1px solid red;
}
.MuiDialogActions-root {
  background-color: var(--chatlook-primary--);
}

/* TOGGLE SWITCH */
.toggle-switch {
  border-radius: 10px;
  /* background: rgb(246, 246, 247); */
  background: var(--chatlook-secondary--);
  width: 105px;
  display: flex;
  user-select: none;
  opacity: 1;
  cursor: pointer;
  position: relative;
  padding: 3px;
  border: 1px solid var(--ev-primary--);
}

.toggle-switch .option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 16px;
  color: rgba(47, 43, 61, 0.78);
  border-radius: 6px;
}

.toggle-switch .rightposi {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translate(0, -50%);
}

.toggle-switch .leftposi {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.toggle-switch .green {
  color: #fff !important;
  background-color: rgb(132, 193, 159) !important;
}

.toggle-switch .red {
  color: #fff !important;
  background-color: rgb(253, 182, 187) !important;
}

.switchmain {
  width: 100px;
  border-radius: 6px;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__input span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.file-input__label {
  text-align: center;
  border: 1px solid #222a40;
  background-color: var(--chatlook-secondary--);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  width: 100%;
  height: 90px;
  position: relative;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.MuiInputBase-input {
  -webkit-text-fill-color: var(--ev-white--) !important;
}

input:-webkit-autofill {
  caret-color: var(--ev-white--) !important;
  -webkit-text-fill-color: white !important;
  outline: none !important;
  border-radius: 1px !important;
  -webkit-box-shadow: 0 0 0 1000px #131722 inset !important; /* Adjusted box shadow to cover full input */
}

